<template>
<v-container class="activity-log-page-wrapper">
    <data-preparation-overlay
        v-if="activityLogsList === null"
    ></data-preparation-overlay>
    <div v-else>
        <v-row>
            <v-col cols="12">
                <h1 class="text-h3">Activity Logs </h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <activity-filter></activity-filter>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <activity-list></activity-list>
            </v-col>
        </v-row>
    </div>
</v-container>
</template>
<script>
import { LOAD_CURRENT_STORE_INVENTORIES } from "@/store/actions.type";
import { mapState } from 'vuex';
import ActivityFilter from "@/views/activity-logs/components/ActivityFilter";
import ActivityList from "@/views/activity-logs/components/ActivityList";
import DataPreparationOverlay from "@/views/components/DataPreparationOverlay";

export default {
    name: "ActivityLoagListingPage",
    components: { 
      DataPreparationOverlay,
      ActivityFilter,
      ActivityList,
    },
    created: async function() {
        await this.$store.dispatch(`shop/init`).then(() => {
            if (this.currentShop != null) {
                this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
            }
        });
        await this.$store.dispatch(`activityLogs/init`)
    },
    computed: {
        ...mapState("shop", [ "currentShop" ]),
        ...mapState("activityLogs", [ "activityLogsList" ]),
    }
    
}
</script>
