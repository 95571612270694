var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5",attrs:{"id":"activity-list"}},[_c('v-data-table',{class:{'disabled': _vm.isLoading},attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.activityLogsList,"items-per-page":_vm.activityLogsList.length,"hide-default-footer":"","no-data-text":"No Activity Logs Available","no-results-text":"No related activity found!","disable-sort":""},scopedSlots:_vm._u([{key:"item.event_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"small-font"},[(item.alert_type === 'success')?_c('v-icon',{attrs:{"small":"","color":"#bbe5b3"}},[_vm._v(" circle ")]):(item.alert_type === 'error')?_c('v-icon',{attrs:{"small":"","color":"#f7888e"}},[_vm._v(" circle ")]):_c('v-icon',{attrs:{"small":"","color":"#f1ce52"}},[_vm._v(" circle ")]),_vm._v(" "+_vm._s(item.type.replace(/_/g, " "))+" ")],1)]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"small-font"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'Y MMM DD'))),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'hh:mm:ssa'))+" ")])]}},{key:"item.shop",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"small-font"},[_vm._v(" "+_vm._s(_vm.shopName(item))+" ")])]}},{key:"item.item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"small-font"},[((typeof item.product_title) != 'undefined')?_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'box']}}):_vm._e(),((typeof item.order_number) != 'undefined')?_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'file-invoice-dollar']}}):_vm._e(),_vm._v(" "+_vm._s(_vm.itemTitle(item))+" ")],1)]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"note",domProps:{"innerHTML":_vm._s(item.content)}})]}}])}),_c('activity-pagination')],1)}
var staticRenderFns = []

export { render, staticRenderFns }