<template>
<v-card id="activity-list" class="mt-5">
  <v-data-table
    :loading="isLoading"
    :headers="headers"
    :items="activityLogsList"
    :items-per-page="activityLogsList.length"
    hide-default-footer
    no-data-text="No Activity Logs Available"
    no-results-text="No related activity found!"
    disable-sort
    :class="{'disabled': isLoading}"
  >
    <template v-slot:item.event_type="{ item }">
      <div class="small-font">
        <v-icon
          v-if="item.alert_type === 'success'"
          small
          color="#bbe5b3"
        >
          circle
        </v-icon>
        <v-icon
          v-else-if="item.alert_type === 'error'"
          small
          color="#f7888e"
        >
          circle
        </v-icon>
        <v-icon
          v-else
          small
          color="#f1ce52"
        >
          circle
        </v-icon>
        {{ item.type.replace(/_/g, " ") }}
      </div>
    </template>
    <template v-slot:item.time="{ item }">
      <div class="small-font">
        {{ item.created_at |  moment('Y MMM DD') }}<br>
        {{ item.created_at |  moment('hh:mm:ssa') }}
      </div>
    </template>
    <template v-slot:item.shop="{ item }">
      <div class="small-font">
        {{ shopName(item) }}
      </div>
    </template>
    <template v-slot:item.item="{ item }">
      <div class="small-font">
        <font-awesome-icon
          v-if="(typeof item.product_title) != 'undefined'"
          class="mr-1"
          :icon="['fas', 'box']" 
        />
        <font-awesome-icon
          v-if="(typeof item.order_number) != 'undefined'"
          class="mr-1"
          :icon="['fas', 'file-invoice-dollar']" />
        {{ itemTitle(item) }}
      </div>
    </template>
    <template v-slot:item.note="{ item }">
      <div class="note" v-html="item.content"></div>
    </template>
  </v-data-table>
  <activity-pagination></activity-pagination>
</v-card>
</template>

<script>
import { mapState } from "vuex";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ActivityPagination from './ActivityPagination.vue';

export default {
  name: "ActivityList",
  components: {
      FontAwesomeIcon,
      ActivityPagination,
  },
  data() {
    return {
      storeTooltip: `(S) = Source Store <br>(D) = Destination Store`,
      headers: [
        { text: 'Time', value: 'time' },
        { text: 'Event Type', value: 'event_type' },
        { text: 'Shop', value: 'shop' },
        { text: 'Item', value: 'item' },
        { text: 'Note', value: 'note' },
      ],
    }
  },
  computed: {
    ...mapState("activityLogs", ["isLoading", "activityLogsList", "currentPage", "lastPage"]),
  },
  methods: {
    shopName(item) {
      if (item.source_store_name) return `(S) ${item.source_store_name}`
      if (item.destination_store_name) return `(D) ${item.destination_store_name}`
      return ''
    },
    itemTitle(item) {
      if (item.product_title) return item.product_title
      if (item.order_number) return item.order_number
      return ''
    },
    circleIndicatorClasses(alertType) {
      return "circle-indicator "+ alertType;
    }
  }
}
</script>

<style lang="scss" scoped>
#activity-list {
  .v-data-table {
    .small-font {
      font-size: 12px;
    }
    .note {
      font-size: 12px;
    } 
    .message {
      font-size: 20px;
    }
  }
}
table {
  margin-bottom: 0;
  border-bottom: 2px solid #ddd;
  width: 100px;
  &.disabled {
    tbody {
      tr {
        td {
          color: #c8c8c8 !important;
        }
      }
    }
  }
}
.fa-question-circle {
  &.hover-text {
    padding: 4px 8px;
    font-size: 16px;
  }
}
</style>
