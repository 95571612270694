<template>
  <v-container class="pagination-wrapper pl-0 pr-0">
    <v-card v-if="!isLoading">
      <v-row>
        <v-col cols="6">
          <span class="pa-4 range-display" v-if="activityLogsPaginate.total > 0">
            {{ range }} of {{ activityLogsPaginate.total }}
          </span>
        </v-col>
        <v-col cols="6">
          <v-btn-toggle 
            class="float-right mr-5"
            v-model="currentPage"
          >
            <v-btn 
              small
              :disabled="currentPage < 2"
              @click="paginatePage(1)"
            >
              <span class="material-icons">
                first_page
              </span>
            </v-btn>
            <v-btn 
              small
              :disabled="currentPage < 2"
              @click="paginatePage(currentPage - 1)"
            >
              <span class="material-icons">
                keyboard_arrow_left
              </span>
            </v-btn>
            <v-btn 
              small
              :disabled="true"
            >
              {{ currentPage}}
            </v-btn>
            <v-btn 
              small
              :disabled="currentPage == activityLogsPaginate.last_page"
              @click="paginatePage(currentPage + 1)"
            >
              <span class="material-icons">
                keyboard_arrow_right
              </span>
            </v-btn>
            <v-btn 
              small
              :disabled="currentPage == activityLogsPaginate.last_page"
              @click="paginatePage(activityLogsPaginate.last_page)"
            >
              <span class="material-icons">
                last_page
              </span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { SET_ACTIVITY_LOGS_PAGINATE_CURRENT_PAGE } from "@/store/mutations.type";
import { FETCH_ACTIVITY_LOGS_BY_URL } from "@/store/actions.type";

export default {
  name: "ActivityPagination",
  props: ["searchStr"],
  data: function() {
    return {
      isLoading: false,
    };
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      if (!(this.productPaginate.search_path != null && newVal != oldVal)) {
        this.paginatePage(newVal);
      }
    },
  },
  computed: {
    ...mapState("activityLogs", [ "activityLogsPaginate" ]),
    currentPage: {
      get() {
        return this.activityLogsPaginate.current_page;
      },
      set(value) {
        return this.$store.commit(`activityLogs/${SET_ACTIVITY_LOGS_PAGINATE_CURRENT_PAGE}`, value, {
          root: true,
        });
      },
    },
    lastPage: {
      get() {
        return this.activityLogsPaginate.last_page;
      },
    },
    range() {
      let index = this.activityLogsPaginate.current_page - 1;
      let limiter = parseInt(this.activityLogsPaginate.limiter);
      let first = index * limiter + 1;
      let last = first + limiter - 1;
      if (last > this.activityLogsPaginate.total || this.activityLogsPaginate.total < limiter) {
        last = this.activityLogsPaginate.total;
      }
      return first+ "-"+last;
    },
  },
  methods: {
    paginatePage(pageNumber) {
      this.isLoading = true;
      this.$store.dispatch(
        `activityLogs/${FETCH_ACTIVITY_LOGS_BY_URL}`, 
        this.activityLogsPaginate.search_path+'&page='+pageNumber
      ).finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.pagination-wrapper {
  .range-display {
    line-height: 0px;
    display: inline-block;
  }
}
</style>
