<template>
<v-card id="activity-filter" class="card activity-filter">
  <v-row class="activity-filter__content flex-div">
    <v-col cols="3" class="activity-filter__select flex-div">
      <span>
        Filters
      </span>
      <v-select
        :items="availableTypes"
        label="Add a filter"
        v-model="selectedType"
        item-text="name"
        item-value="key"
        outlined
        dense
        append-icon="filter_alt"
      >
      </v-select>
    </v-col>
    <v-col cols="5" class="activity-filter__search flex-div">
      <span>
        Search by text
      </span>
      <v-text-field 
        class="search-input"
        v-model="contentStr" 
        append-icon="search"
        outlined
        dense
        @keyup.enter="filterLogs(1)">
      </v-text-field>
    </v-col>
    <v-col cols="4" class="activity-filter__button-div">
      <v-btn
        outlined
        class="white--text action-btn primary mt-5 mr-3" 
        v-on:click="filterLogs(1)"
      >
        Search
      </v-btn>
      <v-btn
        outlined
        class="button secondary mt-5 action-btn" 
        v-on:click="clearFilter()"
      >
        Reset/Clear
      </v-btn>
    </v-col>
  </v-row>
  <v-divider></v-divider>
  <v-card-text class="overflow-hidden pb-0">
      <h5>Active Filters:</h5>
      <v-btn v-for="type in selectedTypes" 
        :key="type" 
        outlined
        class="activity-filter__tag float-left"
      >
        {{ type.replace(/_/g, ' ') }}
        <v-icon
          small
          @click="removedType(type)"
        >
          close
        </v-icon>
      </v-btn>
  </v-card-text>
</v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { FETCH_ACTIVITYLOG_LIST } from "@/store/actions.type";
import { SET_IS_LOADING } from "@/store/mutations.type";

export default {
  name: "ActivityFilter",
  data: function() {
    return {
      selectedType: "all",
      contentStr: null,
      limiter: 15,
      selectedTypes: [],
    };
  },
  watch: {
    selectedType(newType, oldType) {
      if (newType != null && newType != "all" && newType != oldType) {
        this.addToSelectedTypes(newType.replace(/\s/g,'_'));
      }
    },
  },
  computed: {
    ...mapState('activityLogs', ["availableTypes", "isLoading", "currentPage", "lastPage"]),
    notSelectedTypes() {
      if (this.availableTypes === null) {
        return [];
      }
      return this.availableTypes.filter(item => !this.selectedTypes.includes(item))
    },
  },
  methods: {
    ...mapActions("activityLogs", ["init"]),
    filterLogs(pageNum) {
      this.$store.commit(`activityLogs/${SET_IS_LOADING}`, false);
      const postData = {
        filters: {
          type: this.selectedTypes,
          content: this.contentStr,
        },
        limiter: this.limiter,
        page: pageNum
      };
      this.$store.dispatch(`activityLogs/${FETCH_ACTIVITYLOG_LIST}`, postData)
      .finally(() => {
        this.$store.commit(`activityLogs/${SET_IS_LOADING}`, false);
      });
    },
    clearFilter() {
      this.selectedType = "all";
      this.selectedTypes = []
      this.contentStr = null;
      this.filterLogs(1);
    },
    addToSelectedTypes(type) {
      if (!type) return
      if (!this.selectedTypes.includes(type)) {
        this.selectedTypes.push(type);
      }
      this.selectedType = "all";
      this.filterLogs()
    },
    removedType(type) {
      const removeIndex = this.selectedTypes.findIndex(item => item.value === type.value)
      this.selectedTypes.splice(removeIndex, 1);
      this.filterLogs()
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-filter {
  padding: 14px;
  .action-btn {
    height: 40px;
  }

  &__select {
    position: relative;
    flex: 1 1 calc(33.3333% - 16px);

    .fa-caret-down {
      top: 50%;
      right: 24px;
      position: absolute;
      pointer-events: none;
      transform: translateY(-50%);
    }

    > * {
      margin: 0 4px;
    }
  }

  &__search {
    flex: 1 1 calc(33.3333% - 16px);
  }

  &__button-div {
    flex: 1 1 calc(16.6665% - 16px);
  }

  &__border {
    width: 100%;
    margin: 14px 0;
    border-top: 1px solid #9C9C9C;
  }

  &__filters {
    flex-wrap: wrap;
    flex: 1 1 calc(65% - 8px);
  }

  &__tag {
    color: #0F44AD;
    margin: 4px 4px 4px 0px;
    border-radius: 17px;
    padding: 4px 8px 6px 12px;
    background-color: #A6C4FF;
    font-size: 14px;
  }

  &__tag-button {
    padding: 4px;
    color: #0F44AD;
    cursor: pointer;
    margin-left: 4px;
  }

  &__pagination {
    margin: 0;
    justify-content: flex-end;
    flex: 1 1 calc(35% - 8px);
  }
}
</style>
